import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identity as _identity, pickBy as _pickBy } from 'lodash';
import { BehaviorSubject, from, lastValueFrom, NEVER, Observable, of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import {
  Account,
  AccountInfo,
  AccountPeriod,
  AccountService,
  Address,
  AddressData,
  AddressService,
  AddressValidate,
  Alert,
  AnonymousMeterreading,
  AnonymousService,
  BankAccount,
  BankAssignment,
  BankingService,
  BankName,
  BillingPlan,
  BillingPlanService,
  BillingPlanSuggestion,
  CampaignInfo,
  CancelReasonData,
  CheckIdentificationData,
  CheckMeterService,
  Cities,
  CitiesAndStreets,
  CombinedAddressData,
  ConfirmTos,
  ContactMessage,
  ContactService,
  Contract,
  ContractAddResponse,
  ContractCancelData,
  ContractChangeTariff,
  ContractConsumptionSummary,
  ContractInformationSummary,
  ContractMoveData,
  ContractService,
  ContractStartResponse,
  CorrespondenceAddressData,
  DashboardService,
  DeferPaymentData,
  DocumentData,
  DocumentsService,
  EmailVerificationData,
  EntitiesService,
  Feedback,
  FeedbackService,
  Forderung,
  FullAddress,
  KundenfilterService,
  KurzVerbrauchPrognose,
  LegalEntities,
  LoadProfileCustomerMode,
  MaintenanceStatusResponse,
  MetersCheck,
  MetersCheckResult,
  OptInData,
  OptInPostData,
  PartnerAddressData,
  PartnerEmail,
  PersonalUserData,
  Poll,
  PollAnswer,
  ProfileDatas,
  ProfilesService,
  Prognose,
  Salutations,
  SalutationService,
  Sectors,
  SecureService,
  StatusResponse,
  StatusService,
  SuppliersService,
  TariffChangeRequest,
  TariffText,
  TariffTextItem,
  TempToken,
  UpdateablePersonalUserData,
  UserService,
  VerbrauchService,
  VertragsIdentifier,
  VoucherData,
  VouchersService,
  Zaehlerhistorie,
  ZaehlerhistorieEuro,
  ZaehlerIdentifier,
  Zaehlerstand,
  ZaehlerstandService
} from '../../../assets/js/com/ts_api_client';
import { PaymentsService } from '../../../assets/js/com/ts_api_client/api/payments.service';
import { TariffService } from '../../../assets/js/com/ts_api_client/api/tariff.service';
import { PaymentsData } from '../../../assets/js/com/ts_api_client/model/paymentsData';
import { Suppliers } from '../../../assets/js/com/ts_api_client/model/suppliers';
import { TariffSummary } from '../../../assets/js/com/ts_api_client/model/tariffSummary';
import { Environment } from '../../../environments/environment';
import { TENANT_ACRONYM } from '../../bdo/enums/tenant.enum';
import { LoginService } from '../../login/login.service';
import { TRACKING } from '../enums/trackingParts.enum';
import { BdoApiServiceInterface } from './bdo-api.service.interface';
import { CustomerStoreService } from './customer-store.service';
import { CUSTOMERMODE } from '../enums/customerMode';
import { AuthDataStorage } from '../models/AuthData.storage';
import { TariffSummaryParams } from '../models/TariffSummaryParams';

/**
 * Mapping class that connects the Frontend to the generated SDK
 */
@Injectable()
export class BdoApiService implements BdoApiServiceInterface {
  public partner$ = new BehaviorSubject<Account[]>(undefined);
  private tenant: string = TENANT_ACRONYM[Environment.tenant];

  constructor(
    private anonymousService: AnonymousService,
    private accountService: AccountService,
    private entitiesService: EntitiesService,
    private dashboardService: DashboardService,
    private zaehlerService: ZaehlerstandService,
    private kundenfilterService: KundenfilterService,
    private verbrauchService: VerbrauchService,
    private profileService: ProfilesService,
    private customerStore: CustomerStoreService,
    private billingPlanService: BillingPlanService,
    private statusService: StatusService,
    private feedbackService: FeedbackService,
    private contactService: ContactService,
    private contractService: ContractService,
    private userService: UserService,
    private documentService: DocumentsService,
    private paymentsService: PaymentsService,
    private addressService: AddressService,
    private loginService: LoginService,
    private bankingService: BankingService,
    private salutationService: SalutationService,
    private supplierService: SuppliersService,
    private meterService: CheckMeterService,
    private secureService: SecureService,
    private tariffService: TariffService,
    private voucherService: VouchersService,
  ) {
    this.tenant = TENANT_ACRONYM[Environment.tenant];

    this.loginService.onLogout$.subscribe({ next: () => this.onLogout() });
  }

  public getStatus(): Observable<MaintenanceStatusResponse> {
    return this.statusService.getStatus(this.tenant);
  }

  public checkMeter(metersCheck: MetersCheck): Observable<MetersCheckResult> {
    return this.meterService.checkMeter(this.tenant, metersCheck);
  }

  public getBillingPlan(): Observable<BillingPlan> {
    return this.executeWithAuthData((token, accountId) => this.billingPlanService.getBillingPlan(this.tenant, token, accountId));
  }

  public getSimInvoice(accountId: string, endDate: number): Observable<StatusResponse> {
    return this.authToken().pipe(
      switchMap((token) => this.billingPlanService.getSimInvoice(this.tenant, token, accountId, endDate, true))
    );
  }

  public getLoadProfileMode(): Observable<LoadProfileCustomerMode> {
    return this.executeWithAuthData((token, accountId) => this.accountService.getLoadProfileMode(this.tenant, token, accountId));
  }

  public getLoadProfileModeAnonymous(accountId: string, meternumber: string): Observable<LoadProfileCustomerMode> {
    return this.anonymousService.getLoadProfileModeAnonymous(this.tenant, accountId, meternumber);
  }

  public getAccountPeriod(accountId: string): Observable<AccountPeriod> {
    return this.authToken().pipe(
      switchMap((token) => this.billingPlanService.getAccountPeriod(this.tenant, token, accountId))
    );
  }

  public getStreetsByPostCode(postCode: string): Observable<CitiesAndStreets> {
    return this.addressService.getStreetsByPostCode(this.tenant, postCode);
  }

  public getTempToken(): Observable<TempToken> {
    return this.authToken().pipe(
      switchMap((token) => this.secureService.getToken(this.tenant, token))
    );
  }

  public validateAddress(address: Address): Observable<AddressValidate> {
    return this.addressService.checkAddress(this.tenant, address);
  }

  public postContractStart(contract: Contract): Observable<ContractStartResponse> {
    return this.contractService.postContractStart(this.tenant, contract);
  }

  public postContractAdd(contract: Contract): Observable<ContractAddResponse> {
    return this.authToken().pipe(
      switchMap((token) => this.contractService.postContractAdd(this.tenant, token, contract))
    );
  }

  public postContractMove(contract: ContractMoveData): Observable<ContractMoveData> {
    return this.authToken().pipe(
      switchMap((token) => this.contractService.postMoveContract(this.tenant, token, contract))
    );
  }

  public postContractMoveAnonymous(contract: ContractMoveData): Observable<ContractMoveData> {
    return this.contractService.postAnonymousMoveContract(this.tenant, contract);
  }

  public postEmailVerificationAnonymous(emailData: EmailVerificationData): Observable<StatusResponse> {
    return this.anonymousService.postAnonymousEmailVerification(this.tenant, emailData);
  }

  public postContractChange(tariffChange: TariffChangeRequest) {
    return this.authToken().pipe(
      switchMap((token => this.contractService.postChangeContract(this.tenant, token, tariffChange)))
    );
  }

  public postContractChangeAnonymous(tariffChange: TariffChangeRequest): Observable<StatusResponse> {
    return this.anonymousService.postChangeContractAnonymous(this.tenant, tariffChange);
  }

  public postContractEnd(contract: Contract): Observable<Contract> {
    return this.authToken().pipe(
      switchMap((token => this.contractService.postContractEnd(this.tenant, token, contract)))
    );
  }

  public postContractCancelAnonymous(contract: ContractCancelData): Observable<StatusResponse> {
    return this.anonymousService.postAnonymousContractCancel(this.tenant, contract);
  }

  public getContracts(accountId: string, consumption?: number): Observable<ContractInformationSummary> {
    // TODO: add optional consumption
    return this.authToken().pipe(
      switchMap((token => this.contractService.getContractInformation(this.tenant, token, accountId)))
    );
  }

  public getContractsAnonymous(accountId: string, meternumber: string, consumption?: number): Observable<ContractInformationSummary> {
    return this.contractService.getAnonymousContractInformation(this.tenant, accountId, meternumber, consumption);
  }

  public getContractConsumption(accountId: string): Observable<ContractConsumptionSummary> {
    return this.authToken().pipe(
      switchMap((token => this.contractService.getContractConsumptionInformation(this.tenant, token, accountId)))
    );
  }

  public getContractConsumptionAnonymous(accountId: string): Observable<ContractConsumptionSummary> {
    return this.anonymousService.getAnonymousContractConsumptionInfo(this.tenant, accountId);
  }

  public getContractOptions(accountId: string, contractId: string, divisionId: string, consumption: number, customerMode: string): Observable<Array<ContractChangeTariff>> {
    return this.authToken().pipe(
      switchMap((token => this.contractService.getContractChangeTariffOptionsInformation(this.tenant, token, accountId, contractId, divisionId, consumption, customerMode)))
    );
  }

  public getContractOptionsAnonymous(accountId: string, meterNumber: string, contractId: string, divisionId: string, consumption: number, customerMode: string)
    : Observable<Array<ContractChangeTariff>> {
    return this.anonymousService.getAnonymousContractChangeTariffOptionsInformation(this.tenant, accountId, meterNumber, contractId, divisionId, consumption, customerMode);
  }

  public getMoveOutAnonymous(accountId: string, meterNumber: string, filterMeter: boolean){
    return this.anonymousService.getHasVebOut(this.tenant, accountId, meterNumber, filterMeter);
  }

  public getCancelReasons(accountId: string, meterNumber: string){
    return this.anonymousService.getCancelReasonsAnonymous(this.tenant, accountId, meterNumber);
  }

  public getCityByPostCode(postCode: string): Observable<Cities> {
    return this.addressService.getCityByPostCode(this.tenant, postCode);
  }

  public getAccounts(): Observable<Array<Account>> {
    return this.authToken().pipe(
      switchMap((token) => {
        return this.dashboardService.getAccounts(this.tenant, token);
      })
    );
  }

  public getPersonalUserData(): Observable<PersonalUserData> {
    return this.authToken().pipe(
      switchMap((token) => this.userService.getPersonalUserData(this.tenant, token))
    );
  }

  public postPersonalUserData(changedUserData: UpdateablePersonalUserData): Observable<HttpResponse<any>> {
    return this.authToken().pipe(
      switchMap((token) => this.userService.postPersonalUserData(this.tenant, token, changedUserData))
    );
  }

  public getLegalEntities(): Observable<LegalEntities> {
    return this.entitiesService.getLegalEntities(this.tenant);
  }

  public getSectors(): Observable<Sectors> {
    return this.entitiesService.getSectors(this.tenant);
  }

  public getAccountInfo(accountId?: string): Observable<AccountInfo> {
    if (accountId) {
      return this.authToken().pipe(
        switchMap((token) => (this.dashboardService.getAccountInfo(this.tenant, token, accountId)))
      );
    } else {
      return this.executeWithAuthData((token, authAccountId) => this.dashboardService.getAccountInfo(this.tenant, token, authAccountId));
    }
  }

  public getForderung(): Observable<Forderung> {
    return this.executeWithAuthData((token, accountId) => this.dashboardService.getForderung(this.tenant, token, accountId));
  }

  public getDashboardVerbrauchPrognose(): Observable<HttpResponse<KurzVerbrauchPrognose>> {
    return this.executeWithAuthData(
      (token, accountId) => this.dashboardService.getDashboardVerbrauchPrognose(this.tenant, token, accountId, 'response')
    );
  }

  public getFilterVerbrauchPrognose(): Observable<any> {
    return this.executeWithAuthData((token, accountId) => this.kundenfilterService.getKundenFilterVerbrauchPrognose(accountId, this.tenant, token));
  }

  public getZaehlerIdentifiers(changeableOnly?: boolean): Observable<Array<ZaehlerIdentifier>> {
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.getZaehlerIdentifier(this.tenant, token, accountId, changeableOnly));
  }

  public getVertragsIdentifiers(): Observable<VertragsIdentifier> {
    return this.executeWithAuthData((token, accountId) => this.verbrauchService.getVertragsIdentifier(this.tenant, token, accountId));
  }

  public getZaehlerstand(zaehlernummer: string, datum: Date): Observable<Zaehlerstand> {
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.getZaehler(this.tenant, token, accountId, zaehlernummer, datum.getTime()));
  }

  public updateZaehlerstand(zaehlerstand: Zaehlerstand): Observable<HttpResponse<any>> {
    // Replace commas with dots for the Backend
    const copy = JSON.parse(JSON.stringify(zaehlerstand));
    copy.zaehlwerkeListe.map(val => {
      if (val.wert.toString().includes(',')) {
        val.wert = parseFloat(val.wert.toString().replace(',', '.'));
      }
    });
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.putZaehler(this.tenant, token, accountId, zaehlerstand.meterNumber, copy, 'response'));
  }

  public addZaehlerstand(zaehlerstand: Zaehlerstand): Observable<HttpResponse<any>> {
    // Replace commas with dots for the Backend
    const copy = JSON.parse(JSON.stringify(zaehlerstand));
    copy.zaehlwerkeListe.map(val => {
      if (val.wert.toString().includes(',')) {
        val.wert = parseFloat(val.wert.toString().replace(',', '.'));
      }
    });
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.postZaehler(this.tenant, token, accountId, copy, 'response'));
  }

  public getZaehlerStandHistorie(zaehlernummer: string, equipment: string, anzahl?: number): Observable<Zaehlerhistorie> {
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.getZaehlerHistorie(this.tenant, token, accountId, zaehlernummer, equipment, anzahl));
  }

  public getVertragsHistorie(vertragsnummer: string): Observable<Array<Zaehlerhistorie>> {
    return this.executeWithAuthData((token, accountId) => this.verbrauchService.getVertragsHistorie(this.tenant, token, accountId, vertragsnummer));
  }

  /**
   * wmsb
   */
  public getProfiles(contractId: string, aggregation: string, startDate?: number, endDate?: number): Observable<ProfileDatas> {
    return this.executeWithAuthData((token, accountId) => this.profileService.getProfiles(this.tenant, token, accountId, contractId, aggregation, startDate, endDate));
  }

  /**
   * Aktuell fuer die imSys-Daten relevant
   *
   * @param zaehlernummer
   */

  public getZaehlerHistorieEuro(zaehlernummer: string): Observable<ZaehlerhistorieEuro> {
    return this.executeWithAuthData((token, accountId) => this.zaehlerService.getZaehlerHistorieEuro(this.tenant, token, accountId, zaehlernummer));
  }

  public putBillingPlan(billingPlan: BillingPlan): Observable<BillingPlan> {
    return this.executeWithAuthData((token, accountId) => this.billingPlanService.putBillingPlan(this.tenant, token, accountId, billingPlan));
  }

  public getBillingPlanSuggestion(): Observable<BillingPlanSuggestion> {
    return this.executeWithAuthData((token, accountId) => this.billingPlanService.getBillingPlanSuggestion(this.tenant, token, accountId));
  }

  public getPrognose(): Observable<Prognose> {
    return this.executeWithAuthData((token, accountId) => this.verbrauchService.getPrognose(this.tenant, token, accountId));
  }

  public getAlert(): Observable<Alert> {
    return this.executeWithAuthData((token, accountId) => this.dashboardService.getAlert(this.tenant, token, accountId));
  }

  public postAlert(alert: Alert): Observable<any> {
    return this.executeWithAuthData((token, accountId) => this.dashboardService.postAlert(this.tenant, token, accountId, alert));
  }

  // Pass accountId if it is not found in URL
  public getOptin(accountId?: string): Observable<OptInData> {
    if (accountId) {
      return this.authToken().pipe(
        switchMap((token) => this.userService.getOptInUserData(this.tenant, token, accountId)
        )
      );
    } else {
      return this.executeWithAuthData((token, authAccountId) => this.userService.getOptInUserData(this.tenant, token, authAccountId));
    }
  }

  public getOffer(offerId: string, token: string) {
    return this.anonymousService.getAnonymousContractOffer(this.tenant, offerId, token);
  }

  public postOptin(optIn: OptInPostData, accountId: string) {
    return this.authToken().pipe(
      switchMap((token) => this.userService.postOptInUserData(this.tenant, token, accountId, optIn))
    );
  }

  public getToS(): Observable<ConfirmTos> {
    return this.authToken().pipe(
      switchMap((token) => this.userService.getTosConfirmed(this.tenant, token))
    );
  }

  public postToS(): Observable<any> {
    return this.authToken().pipe(
      switchMap((token) => this.userService.confirmToS(this.tenant, token))
    );
  }

  public getEmail(): Observable<PartnerEmail> {
    return this.authToken().pipe(
      switchMap((token) => this.userService.getEmail(this.tenant, token))
    );
  }

  public getUserPostalAddresses() {
    return this.executeWithAuthData((token, accountId) => this.userService.getPostalAddresses(this.tenant, token, accountId));
  }

  public postUserCorrespondenceAddress(data: CorrespondenceAddressData) {
    return this.executeWithAuthData((token, accountId) => this.userService.postCorrespondenceAddress(this.tenant, token, accountId, data));
  }

  public deleteUserCorrespondenceAddress() {
    return this.executeWithAuthData((token, accountId) => this.userService.deleteCorrespondenceAddress(this.tenant, token, accountId));
  }

  public postPartnerAddress(data: PartnerAddressData) {
    return this.authToken().pipe(
      switchMap((token) => this.userService.postPartnerAddress(this.tenant, token, data))
    );
  }

  public getEmailAnonymous(accountId: string, meternumber: string): Observable<PartnerEmail> {
    return this.anonymousService.getEmailAnonymous(this.tenant, accountId, meternumber);
  }

  public getEmailNormalized(authData?: AuthDataStorage): Observable<PartnerEmail> {
    return this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((isLoggedIn) => {
        return isLoggedIn ? this.getEmail() :
          this.getEmailAnonymous(authData?.checkIdentificationData?.accountId, authData?.checkIdentificationData?.meterNumberOrRegisterCode);
      })
    );
  }

  public getAddressAnonymous(accountId: string, meternumber: string): Observable<FullAddress> {
    return this.anonymousService.getAddressAnonymous(this.tenant, accountId, meternumber);
  }

  public getIsBaseSupply(address: AddressData, divisionId: string) {
    return this.addressService.isBaseSupply(this.tenant, address?.postCode, address?.cityName, address?.streetName, address?.houseNum, divisionId);
  }

  // documents
  public getDocumentGroups(): Observable<Array<string>> {
    return this.authToken().pipe(
      switchMap((token) => this.documentService.getDocumentGroups(this.tenant, token))
    );
  }

  public getDocuments(docGroup: string = ''): Observable<Array<DocumentData>> {
    return this.executeWithAuthData((token, accountId) => this.documentService.getDocuments(this.tenant, token, accountId, docGroup));
  }

  // payments
  public getPayments(): Observable<PaymentsData> {
    return this.executeWithAuthData((token, accountId) => this.paymentsService.getPayments(this.tenant, token, accountId));
  }

  public deferPayment(deferPaymentData: DeferPaymentData): Observable<StatusResponse> {
    return this.executeWithAuthData((token, accountId) => this.paymentsService.postDeferPayments(this.tenant, token, accountId, {
          ...deferPaymentData,
          accountId
        })
      );
  }

  // banking
  public getBankAccounts(): Observable<Array<BankAccount>> {
    return this.executeWithAuthData((token, accountId) => this.bankingService.getBankAccounts(this.tenant, token, accountId));
  }

  public getBankNameFromIBAN(iban: string): Observable<BankName> {
    iban = iban.replace(/\s+/g, '');
    return this.anonymousService.getBanknameFromIBAN(this.tenant, iban);
  }

  public postBankAccount(bankAccount: BankAccount): Observable<any> {
    // Trim all spaces in IBAN
    bankAccount.iban = bankAccount.iban.replace(/\s+/g, '');
    return this.executeWithAuthData((token, accountId) => this.bankingService.postBankAccount(this.tenant, token, accountId, bankAccount));
  }

  public getBankAccountAssignment(): Observable<BankAssignment> {
    return this.executeWithAuthData((token, accountId) => this.bankingService.getBankingAssignment(this.tenant, token, accountId)).pipe(
      // löscht alle undefined und null und false values aus dem Objekt, das das Backend uns zum Teil null-values liefert wenn es keine Zuweisung gibt
      map((bankAccountAssignment) => _pickBy(bankAccountAssignment, _identity))
    );
  }

  public getBankAccountAssignmentAnonymous(accountId: string, meterNumber: string): Observable<BankAssignment> {
    return this.anonymousService.getBankingAssignmentAnonymous(this.tenant, accountId, meterNumber).pipe(
      map((bankAccountAssignment) => _pickBy(bankAccountAssignment, _identity))
    );
  }

  public getPoll(pollId: number): Observable<Poll> {
    return this.authToken().pipe(
      switchMap((token) => this.feedbackService.getCustomerSatisfactionPoll(this.tenant, token, pollId))
    );
  }

  public postPollAnswer(answer: PollAnswer): Observable<any> {
    return this.authToken().pipe(
      switchMap((token) => this.feedbackService.postPollQuestion(this.tenant, token, answer.pollId, answer))
    );
  }

  public getVouchers(customerMode: string, address?: Address) {
    return this.voucherService.getMoveVouchers(this.tenant, customerMode, address?.street?.city?.postCode, address?.street?.city?.name, address?.street.name, address?.houseNum);
  }

  public postVouchers(vouchers: VoucherData) {
    return this.voucherService.postMoveVouchers(this.tenant, vouchers);
  }

  // Anonymous
  public getMeterreadingAnonymous(identificationData: CheckIdentificationData): Observable<Zaehlerstand> {
    return this.zaehlerService.getMeterreadingAnonymously(this.tenant, identificationData.accountId, identificationData.meterNumberOrRegisterCode,
      identificationData.atDate?.getTime());
  }

  public postMeterreadingAnonymous(anonymousMeterreading: AnonymousMeterreading): Observable<HttpResponse<Zaehlerstand>> {
    // Replace commas with dots for the Backend
    const copy = JSON.parse(JSON.stringify(anonymousMeterreading));
    copy.meterreading.zaehlwerkeListe.map(val => {
      if (val.wert.toString().includes(',')) {
        val.wert = parseFloat(val.wert.toString().replace(',', '.'));
      }
    });
    return this.zaehlerService.postMeterreadingAnonymously(this.tenant, copy, 'response');
  }

  public checkSmartMeterAnonymous(identificationData: CheckIdentificationData): Observable<StatusResponse> {
    return this.zaehlerService.postCheckSmartMeter(this.tenant, identificationData, 'body');
  }

  public getCustomerModeAnonymous(accountId: string, meterNumber: string) {
    return this.anonymousService.getCustomerModeAnonymous(this.tenant, accountId, meterNumber);
  }

  public getIsEmployeeAnonymous(accountId: string, meterNumber: string): Observable<StatusResponse> {
    return this.anonymousService.isEmployeeAnonymous(this.tenant, accountId, meterNumber);
  }

  public getMetersAnonymously(accountId: string, meterNumber: string) {
    return this.zaehlerService.getMetersAnonymously(this.tenant, accountId, meterNumber);
  }

  public getSalutations(customerMode: CUSTOMERMODE = CUSTOMERMODE.PRIV): Observable<Salutations> {
    return this.salutationService.getPersonSalutations(this.tenant, customerMode);
  }

  public postFeedback(feedback: Feedback): Observable<HttpResponse<any>> {
    return this.authToken().pipe(
      switchMap((token) => this.feedbackService.postFeedback(this.tenant, token, feedback))
    );
  }

  public postContactMessage(message: ContactMessage) {
    return this.authToken().pipe(
      switchMap((token) => this.contactService.postContactMessage(this.tenant, token, message))
    );
  }

  public getSuppliers(division: string): Observable<Suppliers> {
    return this.supplierService.getSuppliers(this.tenant, division);
  }

  public getTariff(params: TariffSummaryParams): Observable<TariffSummary> {
    return this.tariffService.getTariffSummaries(
      this.tenant,
      params.beginDate,
      params.division,
      params.annualConsumption,
      params.postCode,
      params.annualConsumptionNt,
      params.productIds,
      params.cityName,
      params.streetName,
      params.houseNum,
      params.process,
      params.customerMode,
      params.offerId
    );
  }

  public getTariffText(key: string): Observable<TariffTextItem> {
    return this.tariffService.getText(this.tenant, key);
  }

  public getAvailableProducts(address: Address) {
    return this.addressService.checkAvailableProductsByPostCodeAndAddress(this.tenant, address);
  }

  public getBdewWorkdayOffset(workdayOffset: string): Observable<StatusResponse> {
    return this.anonymousService.getBdewWorkdayOffsetAnonymous(this.tenant, workdayOffset);
  }

  public getCampaignInfos(accountId: string): Observable<Array<CampaignInfo>> {
    return this.authToken().pipe(
      switchMap((token) => this.dashboardService.getCampaignInfo(this.tenant, token, accountId))
    );
  }


  /**
   * reset state
   * @private
   */
  private onLogout() {
    this.partner$.next(undefined);
    this.customerStore.setAccountId(undefined);
  }

  private authToken(): Observable<string> {
    return this.loginService.getToken().pipe(
      map((token) => `Bearer ${token}`)
    );
  }

  /**
   * wrapping method for an api call, that needs the authToken and the accountId
   * @example executeWithAuthData((token, accountId) => getMyAPIRessource(token, accountId, anotherParam))
   * @param apiCall
   * @private
   */
  private executeWithAuthData<T>(apiCall: (token: string, accountId: string) => Observable<T>): Observable<T> {
    return this.authToken().pipe(
      switchMap((token) => apiCall(token, this.customerStore.getAccountId()))
    );
  }
}

export class MockApiService implements BdoApiServiceInterface {

  public getStatus(): Observable<any> {
    return of([]);
  }

  init(): Observable<Account> {
    return NEVER;
  }

  public getAccounts(): Observable<Array<Account>> {
    return NEVER;
  }

  public getPersonalUserData(): Observable<PersonalUserData> {
    return NEVER;
  }

  public postPersonalUserData(changedUserData: UpdateablePersonalUserData): Observable<HttpResponse<any>> {
    return NEVER;
  }

  public getAccountInfo(): Observable<AccountInfo> {
    return NEVER;
  }

  public getForderung(): Observable<Forderung> {
    return NEVER;
  }

  public getDashboardVerbrauchPrognose(): Observable<HttpResponse<KurzVerbrauchPrognose>> {
    return NEVER;
  }

  public getFilterVerbrauchPrognose(): Observable<any> {
    return NEVER;
  }

  public getZaehlerIdentifiers(changeableOnly?: boolean): Observable<Array<ZaehlerIdentifier>> {
    return NEVER;
  }

  public getVertragsIdentifiers(): Observable<VertragsIdentifier> {
    return NEVER;
  }

  public getVertragsHistorie(vertragsnummer: string): Observable<Array<Zaehlerhistorie>> {
    return of([]);
  }

  public getZaehlerstand(zaehlernummer: string, datum: Date): Observable<Zaehlerstand> {
    return NEVER;
  }

  public updateZaehlerstand(zaehlerstand: Zaehlerstand): Observable<HttpResponse<any>> {
    return NEVER;
  }

  public addZaehlerstand(zaehlerstand: Zaehlerstand): Observable<HttpResponse<any>> {
    return NEVER;
  }

  public getZaehlerStandHistorie(zaehlernummer: string, equipment: string, anzahl?: number): Observable<Zaehlerhistorie> {
    return NEVER;
  }

  public getZaehlerHistorieEuro(zaehlernummer: string): Observable<ZaehlerhistorieEuro> {
    return NEVER;
  }

  public getPrognose(): Observable<Prognose> {
    return NEVER;
  }

  public getDocumentGroups(): Observable<Array<string>> {
    return of([]);
  }

  public getDocuments(): Observable<Array<DocumentData>> {
    return of([]);
  }

  public getPayments(): Observable<PaymentsData> {
    return NEVER;
  }

  public getBankAccounts(): Observable<Array<BankAccount>> {
    return of([]);
  }

  public getBankNameFromIBAN(): Observable<BankName> {
    return NEVER;
  }

  public postBankAccount(bankAccount: BankAccount) {
    return NEVER;
  }

  public deferPayment(deferPaymentData: DeferPaymentData): Observable<StatusResponse> {
    return NEVER;
  }

  public getToS(): Observable<ConfirmTos> {
    return NEVER;
  }

  public getBankAccountAssignment(): Observable<BankAssignment> {
    return NEVER;
  }

  public getBankAccountAssignmentAnonymous(accountId: string, meterNumber: string): Observable<BankAssignment> {
    return NEVER;
  }

  public getMeterreadingAnonymous(identificationData: CheckIdentificationData): Observable<Zaehlerstand> {
    return NEVER;
  }

  public postMeterreadingAnonymous(anonymousMeterreading: AnonymousMeterreading): Observable<AnonymousMeterreading> {
    return NEVER;
  }

  public getMetersAnonymously(accountId: string, meterNumber: string): Observable<ZaehlerIdentifier[]> {
    return of([]);
  }

  public getIsEmployeeAnonymous(accountId: string, meterNumber: string): Observable<StatusResponse> {
    return NEVER;
  }

  public postToS(): Observable<any> {
    return NEVER;
  }

  public postFeedback(feedback: Feedback): Observable<HttpResponse<any>> {
    return NEVER;
  }

  public postTracking(type: TRACKING.LOCATION, action: TRACKING.ACTION, target: TRACKING.LOCATION | TRACKING.ELEMENTS = TRACKING.LOCATION.EMPTY): void {
  }

  public postSimpleTracking(name: string, action?: string): void {
  }

  public getAlert(): Observable<Alert> {
    return NEVER;
  }

  public postAlert(alert: Alert): Observable<any> {
    return NEVER;
  }

  public getBillingPlan(): Observable<BillingPlan> {
    return NEVER;
  }

  public getSimInvoice(endDate: number): Observable<StatusResponse> {
    return NEVER;
  }

  public getAccountPeriod(accountId: string): Observable<AccountPeriod> {
    return NEVER;
  }

  public putBillingPlan(billingPlan: BillingPlan): Observable<BillingPlan> {
    return NEVER;
  }

  public getBillingPlanSuggestion(): Observable<BillingPlanSuggestion> {
    return NEVER;
  }


  public getProfiles(contractId: string, aggregation: string, startDate?: number, endDate?: number): Observable<ProfileDatas> {
    return NEVER;
  }

  public getSalutations(): Observable<Salutations> {
    return NEVER;
  }

  public getSuppliers(division: string): Observable<Suppliers> {
    return NEVER;
  }

  public getTariff(postCode: string, beginDate: number, hash: string, annualConsumption: number, prodConstId: string, tariff: string, type: string): Observable<TariffSummary> {
    return NEVER;
  }

  public getPoll(pollId: number): Observable<Poll> {
    return NEVER;
  }

  public postPollAnswer(answer: PollAnswer): Observable<any> {
    return NEVER;
  }

  public getEmail(): Observable<PartnerEmail> {
    return NEVER;
  }

  public getEmailAnonymous(accountId: string, meternumber: string): Observable<PartnerEmail> {
    return NEVER;
  }

  public getAvailableProducts(address: Address) {
    return NEVER;
  }

  public getAddressAnonymous(accountId: string, meternumber: string) {
    return NEVER;
  }

  public getVouchers(customerMode: string, address: Address = {}) {
    return NEVER;
  }

  public postVouchers(vouchers: VoucherData) {
    return NEVER;
  }

  public postContractMove(contract: ContractMoveData): Observable<ContractMoveData> {
    return NEVER;
  }

  public postContractMoveAnonymous(contract: ContractMoveData): Observable<ContractMoveData> {
    return NEVER;
  }

  public postContractChange(tariffChang: TariffChangeRequest): Observable<StatusResponse> {
    return NEVER;
  }

  public postContractEnd(contract: Contract): Observable<Contract> {
    return NEVER;
  }

  public getBdewWorkdayOffset(workdayOffset: string): Observable<StatusResponse> {
    return NEVER;
  }

  public getSectors(): Observable<Sectors> {
    return NEVER;
  }

  public getLegalEntities(): Observable<LegalEntities> {
    return NEVER;
  }

  public getUserPostalAddresses(accountId: string) {
    return NEVER;
  }

  public postUserCorrespondenceAddress(accountId: string, data: CombinedAddressData) {
    return NEVER;
  }

  public deleteUserCorrespondenceAddress(data: PartnerAddressData) {
    return NEVER;
  }

  public postPartnerAddress(data: PartnerAddressData) {
    return NEVER;
  }

  public getContracts(accountId: string): Observable<ContractInformationSummary> {
    return NEVER;
  }

  public getContractConsumption(accountId: string): Observable<ContractConsumptionSummary> {
    return NEVER;
  }

  public getContractOptions(accountId: string, divisionId: string, consumption: number, customerMode: string): Observable<Array<ContractChangeTariff>> {
    return of([]);
  }

  public getIsBaseSupply(address: AddressData, divisionId: string) {
    return NEVER;
  }

  public postContractAdd(contract: Contract): Observable<Contract> {
    return NEVER;
  }

  public getProductIdForFrameContract(accountId: string, frameContractId: string, optionId?: string): Observable<StatusResponse> {
    return NEVER;
  }

  public getOptin(): Observable<OptInData> {
    return NEVER;
  }

  public postOptin(data: OptInData, accountId: string) {
    return NEVER;
  }

  public getCampaignInfos(accountId: string): Observable<Array<CampaignInfo>> {
    return NEVER;
  }

  public getCancelReasons(accountId: string, meterNumber: string): Observable<CancelReasonData[]>{
    return NEVER;
  }

  public getTariffText(key: string): Observable<Array<TariffText>> {
    return NEVER;
  }

  public getEmailNormalized(authData?: AuthDataStorage): Observable<PartnerEmail> {
    return NEVER;
  }

  public getMoveOutAnonymous(accountId: string, meterNumber: string){
    return NEVER;
  }

  public getOffer(offerId: string, token: string) {
    return NEVER;
  }

  public postEmailVerificationAnonymous(emailData: EmailVerificationData): Observable<StatusResponse> {
    return NEVER;
  }

}
