import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Validates an accountId
 * AccountIds may start with up to 3 optional Zeroes, a 2 or 8 and may not be longer than 12 digits
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 * @param control
 * @param errorText
 */
export function accountIdValidator(translateService: TranslateService, errorText: string = '') {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
    const isValid = isValidAccountId(control.value);
    if (isValid) {
      return null;
    }
    errorText = errorText ? errorText : translateService.instant('register.account.accountIdInvalid');
    return { ['accountIdPattern']: (errorText ? errorText : true) };
  };
}

/**
 * accountId is valid, if it is empty or if it matches our validator
 * @param accountId
 */
export function isValidAccountId(accountId: string): boolean {

  if (!accountId) {
    return true;
  }
  // Throw away the first 0-3 zeroes
  const stringWithoutLeadingZeros = accountId.replace(/^0{0,3}/, '');
  return (stringWithoutLeadingZeros.startsWith('2') || stringWithoutLeadingZeros.startsWith('8'))
    && [9, 10].includes(stringWithoutLeadingZeros.length) // only length of 9 or 10 are allowed
    && accountId.length <= 12; // with leadingZeros, length can be up until 12
}
