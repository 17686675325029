/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ActivationData } from '../model/activationData';
// @ts-ignore
import { CheckIdentificationData } from '../model/checkIdentificationData';
// @ts-ignore
import { CheckUsernameResponse } from '../model/checkUsernameResponse';
// @ts-ignore
import { RegistrationData } from '../model/registrationData';
// @ts-ignore
import { StatusResponse } from '../model/statusResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

    protected basePath = 'http://localhost:8080/BdoSpiders/orca';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Prueft, ob der angegebene Username bereits vergeben ist
     * 
     * @param xTenant 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCheckUsername(xTenant: string, username: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<CheckUsernameResponse>;
    public getCheckUsername(xTenant: string, username: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CheckUsernameResponse>>;
    public getCheckUsername(xTenant: string, username: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CheckUsernameResponse>>;
    public getCheckUsername(xTenant: string, username: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCheckUsername.');
        }
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getCheckUsername.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/auth/checkUsername/${this.configuration.encodeParam({name: "username", value: username, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<CheckUsernameResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prueft, ob der angegebene Username bereits in Salesforce vergeben ist
     * 
     * @param xTenant 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCheckUsernameSalesforce(xTenant: string, username: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<CheckUsernameResponse>;
    public getCheckUsernameSalesforce(xTenant: string, username: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CheckUsernameResponse>>;
    public getCheckUsernameSalesforce(xTenant: string, username: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CheckUsernameResponse>>;
    public getCheckUsernameSalesforce(xTenant: string, username: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getCheckUsernameSalesforce.');
        }
        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling getCheckUsernameSalesforce.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/auth/checkUsernameSalesforce/${this.configuration.encodeParam({name: "username", value: username, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<CheckUsernameResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktiviert ueber ein mitgeliefertes Token einen bestimmten Nutzeraccount
     * activated user account via a given token
     * @param xTenant 
     * @param activationData Data for Activation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActivate(xTenant: string, activationData: ActivationData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postActivate(xTenant: string, activationData: ActivationData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postActivate(xTenant: string, activationData: ActivationData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postActivate(xTenant: string, activationData: ActivationData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postActivate.');
        }
        if (activationData === null || activationData === undefined) {
            throw new Error('Required parameter activationData was null or undefined when calling postActivate.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/auth/activate`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: activationData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Prueft, ob die angegebene Kombination aus Zaehlernummer und Vertragskontonummer vorhanden ist.
     * E2E test in CheckIdentificationTest
     * @param xTenant 
     * @param checkIdentificationData Combination for identification
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCheckIdentification(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postCheckIdentification(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postCheckIdentification(xTenant: string, checkIdentificationData: CheckIdentificationData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postCheckIdentification(xTenant: string, checkIdentificationData: CheckIdentificationData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postCheckIdentification.');
        }
        if (checkIdentificationData === null || checkIdentificationData === undefined) {
            throw new Error('Required parameter checkIdentificationData was null or undefined when calling postCheckIdentification.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/auth/checkIdentification`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: checkIdentificationData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registriert einen Kunden und schließt damit den Registrierungsprozess ab
     * 
     * @param xTenant 
     * @param registrationData Data for Registration
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRegistration(xTenant: string, registrationData: RegistrationData, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<StatusResponse>;
    public postRegistration(xTenant: string, registrationData: RegistrationData, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<StatusResponse>>;
    public postRegistration(xTenant: string, registrationData: RegistrationData, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<StatusResponse>>;
    public postRegistration(xTenant: string, registrationData: RegistrationData, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling postRegistration.');
        }
        if (registrationData === null || registrationData === undefined) {
            throw new Error('Required parameter registrationData was null or undefined when calling postRegistration.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/auth/register`;
        return this.httpClient.request<StatusResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: registrationData,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
