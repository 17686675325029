import { STAGE } from '../app/bdo/enums/stage.enum';
import { TENANT } from '../app/bdo/enums/tenant.enum';
import { AwsAuthConfig } from '../app/bdo/models/AwsAuthConfig';
import { signal, WritableSignal } from '@angular/core';

export class Environment {
  static tariffChangedDisabledTenants = [TENANT.StadtwerkeLeichlingen];
  static tenant: TENANT = TENANT.Rheinenergie;
  static kameleoon: {
    siteId: string;
    domain: string
  };
  static stage: STAGE = STAGE.Development;
  static awsAuthConfig: AwsAuthConfig;
  static baseHref: string;
  static apiTags: string;
  static branch: string;
  static config;
  static version: {
    revision: string;
    branch: string;
  };

  static tariffChangeIsEnabled(){
    return !this.tariffChangedDisabledTenants.includes(this.tenant);
  }
}

export const sfMap = {
  [STAGE.Development]: 'https://rheinenergie--dev.sandbox.my.site.com',
  [STAGE.Staging]: 'https://service-test.rheinenergie.com',
  [STAGE.Production]: 'https://service.rheinenergie.com'
};

export class URLs {
  static CONSTRUCTION_SITE_URL = '/baustelle';
  static backend: string = 'http://localhost:8080/BdoSpiders/orca';

  // default, gets overwritten as soon as config.json has been loaded in main.ts
  static sfLoginUrl: WritableSignal<string> = signal(sfMap[STAGE.Development] + '/login');
}

export class DatadogConfig {
  static applicationId: string;
  static clientToken: string;
  static appVersion: string;
}

export class LinksMarketingPixelConfig {
  static affiliate: string;
  static tellja: string;
}
